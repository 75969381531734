.x-checkbox
  position: relative
  &__input
    position: absolute
    top: 0
    left: 0
    width: 0
    height: 0
    z-index: -1
    opacity: 0.01
  &__box
    display: block
    border-radius: 2px
    width: 16px
    height: 16px
    box-sizing: border-box
    border: 2px solid var(--check-radio-static)
    &-mark
      display: none
  &__input:checked ~ &__box
    color: var(--check-radio-white)
    border-width: 0
    background-color: var(--check-radio-active)
  &__input:checked[disabled] ~ &__box
    background-color: var(--check-radio-static)
  &__input:checked ~ &__box .x-checkbox__box-mark
    display: block
