import { Component, Host, h, Prop } from '@stencil/core';

@Component({
  tag: 'x-checkbox',
  styleUrl: 'x-checkbox.sass',
})
export class XCheckbox {
  @Prop() name: string;
  @Prop() value: string;
  @Prop() checked: boolean;
  @Prop() disabled: boolean;

  render() {
    return (
      <Host class="x-checkbox">
        <input type="checkbox" class="x-checkbox__input" name={this.name} value={this.value} checked={this.checked} disabled={this.disabled} />
        <span class="x-checkbox__box">
          <span class="x-checkbox__box-mark">
            <x-icon glyph="checkmark" size="16"></x-icon>
          </span>
        </span>
      </Host>
    );
  }
}
